<template>
  <div class="content">
    <div v-for="(item,index) in myList" :key="index" class="exh">
      <p class="exh_title">{{ item.myTitle }}</p>
      <p class="title_text">您认为{{ item.myTitle }}的同事和分数</p>
      <ul>
        <li v-for="(v,i) in item.childrenList" :key="i">{{ v.name }} {{ v.score }}</li>
      </ul>
    </div>
    <div class="exh_btn">
      <van-button color="#ddd" @click="froward">上一步</van-button>
      <van-button type="danger" @click="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { toSubmit, getCultureResultApi } from '@/api/assessment'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      data: [],
      exam_date: '',
      name: 'answer',
      page: 0,
      myList: []
    }
  },
  created() {
    this.page = this.$route.query.page
    this.exam_date = sessionStorage.getItem('DATE')
    this.getData()
  },
  methods: {
    froward() {
      sessionStorage.setItem('isAgain', 0)
      this.$router.go(-1)
    },
    submit() {
      const msg = '确认提交吗，提交之后不可更改'
      Dialog.confirm({
        title: '提示',
        message: msg
      }).then(() => {
        this.funcToSubmit()
      }).catch(() => {

      })

      // if (confirm('请确认之后再提交，提交之后不可更改')) {
      //   const params = {
      //     exam_data: this.exam_date,
      //     page: this.page,
      //     name: 'answer'
      //   }
      //   toSubmit(params).then(res => {
      //     if (res.errNo === 0) {
      //       this.$router.push({ path: '/assuccess' })
      //     } else {
      //       Toast(res.result)
      //     }
      //   })
      // }
    },
    funcToSubmit() {
      const params = {
        exam_data: this.exam_date,
        page: this.page,
        name: 'answer'
      }
      toSubmit(params).then(res => {
        if (res.errNo === 0) {
          this.$router.push({ path: '/assessment/assuccess' })
        } else {
          Toast(res.msg)
        }
      })
    },
    getData() {
      const params = {
        exam_date: this.exam_date,
        page: this.page,
        name: 'answer'
      }
      getCultureResultApi(params).then(res => {
        const keys = Object.keys(res.result.result)
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          const value = res.result.result[key]
          this.myList.push({
            myTitle: key,
            childrenList: value
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.exh {
    padding: 20px 30px 0;
    .exh_title {
        font-size: 40px;
        width: 300px;
        height: 80px;
        background: #f00;
        color: #fff;
        text-align: center;
        line-height: 80px;
        margin: 0 auto;
    }
    .title_text {
        padding: 30px 0;
        font-size: 26px;
    }
    li{
        margin-top: 10px;
        font-size: 26px;
    }
}
.exh_btn {
    text-align: center;
    padding: 30px 0;
}
/deep/ .van-button {
    color: #fff;
    width: 200px;
    margin-right: 30px;
    height: 70px;
}
</style>
